import { I18nUtils } from '@modules/I18nUtils.js'
import './assets/css/predefined.css'
import '@static/consts/index'

const defaultDatetimeFormat = {
  short: {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  },
}

const i18n = I18nUtils.setupI18n( {
  locale: 'en',
  datetimeFormats: {
    'en': defaultDatetimeFormat,
    'ru': defaultDatetimeFormat,
    'es': defaultDatetimeFormat,
  },
} as any )
import( './app' )

export {
  i18n,
}
